import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa'; 
import './subsections.css';
import { Link } from 'react-router-dom'

const Aktuelles = () => {
  return (
    <div className="app-container">
      <div className="content">
        <h1 className="title">Aktuelles und Öffnungszeiten</h1>
        <p className="text">Die Skipiste ist an Schneetagen für dich geöffnet. Schau gerne vorbei!
        <br/>
         Auch kannst du dich hier auf aktuelle Events freuen, die bald aktualisiert werden. Ansonsten sind Feierlichkeiten etc. auch häufig schon vorher auf unseren Social Media Kanälen angekündigt.</p>
        <div className="download-section">
          <Link to='https://www.facebook.com/SkiClubBuch/?locale=de_DE'
          target='_blank' className="download" style={{backgroundColor: '#3b5998'}}><FaFacebook style={{marginRight: '10px'}}/>Facebook</Link>
          <Link to='https://www.instagram.com/ '
          target='_blank' className="download" style={{backgroundColor: '#E1306C'}}><FaInstagram style={{marginRight: '10px'}}/>Instagram</Link>
        </div>
      </div>
    </div>
  );
};


export default Aktuelles;