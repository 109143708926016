import React from 'react';

const Impressum = () => {
    return (
        <div className="app-container">
            <div className="content">
                <h1>Impressum</h1>

                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>Muster e. V.<br />
                    Musterstra&szlig;e 111<br />
                    Geb&auml;ude 44<br />
                    90210 Musterstadt</p>

                <p>Vereinsregister: HRB 999999<br />
                    Registergericht: Amtsgericht Musterstadt</p>

                <p><strong>Vertreten durch:</strong><br />
                    Dr. Harry Mustermann<br />
                    Luise Beispiel</p>

                <h2>Kontakt</h2>
                <p>Telefon: +49 (0) 123 44 55 66<br />
                    Telefax: +49 (0) 123 44 55 99<br />
                    E-Mail: mustermann@musterfirma.de</p>

                <h2>Umsatzsteuer-ID</h2>
                <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                    DE999999999</p>

                <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>


            </div>
        </div>
    );
};

export default Impressum;