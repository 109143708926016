import React from 'react';
import { IoPersonCircleOutline } from 'react-icons/io5';


const members = {
  presidents: [{ name: 'Max Müller' }, { name: 'Julia Schmidt' }],
  secretary: [{ name: 'Thomas Meier' }],
  treasurers: [{ name: 'Martina Lehmann' }],
  auditors: [{ name: 'Oliver Schneider' }],
  assessors: ['Sarah Fischer', 'Bernd Meyer', 'Stefan Koch', 'Claudia Pohl'],
};

const Role = ({ title, members }) => (
  <div className="role">
    <h2 className="subtitle">{title}</h2>
    {members.map((member, i) => (
      <p key={i} className="member">
        <IoPersonCircleOutline className="memberIcon" size={20} />
        {member.name}
      </p>
    ))}
  </div>
);

function VorstandSeite() {
  return (
    <div className="app-container">
      <div className="content">
        <h1 className="title">Unser Vorstand</h1>
        <div className="board-roles">
          <Role title="Vorsitzende" members={members.presidents} />
          <Role title="Schriftführer" members={members.secretary} />
          <Role title="Kassenwart" members={members.treasurers} />
          <Role title="Kassenprüfer" members={members.auditors} />
        </div>
        <div className="assessors">
          <h2 className="subtitle">Beisitzende</h2>
          <p>{members.assessors.join(', ')}</p>
        </div>
      </div>
    </div>
  );
}

export default VorstandSeite;