import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Kontakt</h2>
           <p>Kontaktiere uns gerne unter dieser Email</p>
          </div>
          <div className='footer-link-items'>
            <h2>Informationen</h2>
            <Link to='/vorstand'>Vorstandschaft</Link>
            <Link to='/datenschutz'>Datenschutzerklärung</Link>
            <Link to='/impressum'>Impressum</Link>
          </div>
        </div>
        <div className='footer-link-items'>
          <h2>Social Media</h2>
          <Link to='https://www.instagram.com/'
          target='_blank'>Instagram</Link>
          <Link to='https://www.facebook.com/SkiClubBuch/?locale=de_DE'
          target='_blank'>Facebook</Link>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              <img src="/images/Skiclub-Logo.png" alt="Logo" className='logo' />
            </Link>
          </div>
          <small className='website-rights'>Skiclub Buch e.V. © 2023</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link facebook'
              to='https://www.facebook.com/SkiClubBuch/?locale=de_DE'
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </Link>
            <Link
              className='social-icon-link instagram'
              to='https://www.instagram.com/'
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;