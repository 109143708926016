import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa'; 
import './subsections.css';
import { Link } from 'react-router-dom'

const Freizeiten = () => {
  return (
    <div className="app-container">
      <div className="content">
        <h1 className="title">Ausflüge</h1>
        <p className="text">In der Vergangenheit haben viele spannende und unterhaltsame Ausflüge stattgefunden. Aktuell sind leider keine Ausflüge geplant, aber wir werden diese Seite schnellstmöglich aktualisieren, sobald neue Ausflüge feststehen.<br/>
        Sie können sich auch auf unseren Social-Media-Kanälen auf dem Laufenden halten.</p>
        <div className="download-section">
          <Link to='https://www.facebook.com/SkiClubBuch/?locale=de_DE'
          target='_blank' className="download" style={{backgroundColor: '#3b5998'}}><FaFacebook style={{marginRight: '10px'}}/>Facebook</Link>
          <Link to='https://www.instagram.com/ '
          target='_blank' className="download" style={{backgroundColor: '#E1306C'}}><FaInstagram style={{marginRight: '10px'}}/>Instagram</Link>
        </div>
      </div>
    </div>
  );
};

export default Freizeiten;