import React from 'react';
import '../App.css';
import { Button } from './Button';
import { Link as ScrollLink } from 'react-scroll'; 
import './HeroSection.css';
import './Cards'

function HeroSection() {
  return (
    //* /videos/video-1.mp4
    <div className='hero-container'>
      {/*<video src='/videos/video-1.mp4' autoPlay loop muted /> */}

      <h1>SKICLUB BUCH</h1>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          MITGLIED WERDEN
        </Button>
        
          
          <ScrollLink to="section1" smooth={true} duration={700}>
          <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={e => e.preventDefault()}
        >
            ENTDECKEN <i className='far fa-play-circle' />
            </Button>
          </ScrollLink>
        
      </div>
    </div>
  );
}

export default HeroSection;
