import React from 'react';

const Impressionen = () => {
  const images = [
     
    { src: "images/Schneepiste.jpg", alt: "Schneepiste", title: "Schneepiste" },
    { src: "images/Praeparierung.jpeg", alt: "Praeparierung", title: "Praeparierung" },
    { src: "images/Skidu.jpeg", alt: "Skidu", title: "Skidu" },
    { src: "images/Huettenwagen.jpg", alt: "Hüttenwagen", title: "Hüttenwagen" },
  ];

  return (
    <div className='app-container'>
      <div className="impressionen-content">
        <h1 id='section1' style={{paddingBottom: '30px'}}>Impressionen und Geschichte(folgt)</h1>
        <div className="image-grid">
          {images.map((image, i) => (
            <div className="image-container" key={i}>
              <img src={image.src} alt={image.alt} />
              <div className="image-title">{image.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Impressionen;